import MousePosition from 'ol/control/MousePosition';
import OSM from 'ol/source/OSM';

import { createStringXY, format } from 'ol/coordinate';
import { defaults as defaultControls } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import { transform } from 'ol/proj';
import Stamen from 'ol/source/Stamen';
import Select from 'ol/interaction/Select';
import VectorSource from 'ol/source/Vector';
import { Draw, Modify, Snap } from 'ol/interaction';
import View from 'ol/View';
import { Icon, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';

import ImageLayer from 'ol/layer/Image';
//import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
//import View from 'ol/View';
import { getCenter } from 'ol/extent';
import { Circle, Polygon } from 'ol/geom';
import { Circle as CircleStyle, Fill, Stroke } from 'ol/style';
import {getVectorContext} from 'ol/render';
// Map views always need a projection.  Here we just want to map image
// coordinates directly to map coordinates, so we create a projection that uses
// the image extent in pixels.



const raster = new TileLayer({
  source: new OSM(),
});

const source = new VectorSource();
const vector = new VectorLayer({
  source: source,
  style: {
    'fill-color': 'rgba(255, 255, 255, 0.2)',
    'stroke-color': '#ffcc33',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': '#ffcc33',
  },
});



const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});
closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

const extent = [0, 0, 800, 698];
const projection = new Projection({
  code: 'xkcd-image',
  units: 'pixels',
  extent: extent,
});

var arrayfeatures = [];
var names = [];
for (let i = 0; i < dataPlaces.length; i++) {
  if (dataPlaces[i].agurbCoords !== null && dataPlaces[i].agurbCoords["type"] === "polygon") {
    var polygonCoord = dataPlaces[i].agurbCoords["polygonCoord"];
    var name = dataPlaces[i].name;
    arrayfeatures.push(new Feature(new Polygon(JSON.parse('[[' + polygonCoord + ']]'))));
    var description = dataPlaces[i].description;
    var number = parseInt(dataPlaces[i].placeNumber);
    arrayfeatures[arrayfeatures.length - 1].set("name", name);
    arrayfeatures[arrayfeatures.length - 1].set("desc", description);
    arrayfeatures[arrayfeatures.length - 1].set("number", number);
    names.push(name);
  }
  if (dataPlaces[i].agurbCoords !== null && dataPlaces[i].agurbCoords["type"] === "circle") {
    var circleX = parseFloat(dataPlaces[i].agurbCoords["coordX"]);
    var circleY = parseFloat(dataPlaces[i].agurbCoords["coordY"]);
    var circleRadius = parseFloat(dataPlaces[i].agurbCoords["radius"]);
    var centerCircle = [circleX, circleY];

    arrayfeatures.push(new Feature(new Circle(centerCircle, circleRadius)));
    var name = dataPlaces[i].name;
    var description = dataPlaces[i].description;
    var number = parseInt(dataPlaces[i].placeNumber);
    arrayfeatures[arrayfeatures.length - 1].set("name", name);
    arrayfeatures[arrayfeatures.length - 1].set("desc", description);
    arrayfeatures[arrayfeatures.length - 1].set("number", number);
    names.push(name);
  }
}
for (let j = 0; j < arrayfeatures.length; j++) {
  arrayfeatures[j].set('style', new Style({
    stroke: new Stroke({
      color: 'transparent',
      width: 0,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0)',
    }),
    cursor: 'pointer'

  }));

}


const view = new View({
  projection: projection,
  center: getCenter(extent),
  zoom: 1,
  maxZoom: 8,
  minZoom: 1,
  extent: [0, 0, 800, 698]

});
const test=[];
const map = new Map({
  //controls: defaultControls().extend([mousePositionControl]),
  layers: [
    new ImageLayer({
      source: new Static({
        attributions: '© <a href="https://auc.ma">Agence urbaine de casablanca</a>',
        url: url,
        projection: projection,
        imageExtent: extent,
      }),
    }), new VectorLayer({
      style: function (feature) {
        return feature.get('style');
      },
      source: new VectorSource({ features: arrayfeatures }),
    }),
    raster, vector
  ],
  overlays: [overlay],
  target: 'map',
  view: view,
});

map.on('loadstart', function () {
  map.getTargetElement().classList.add('spinner');
});
map.on('loadend', function () {
  map.getTargetElement().classList.remove('spinner');
});

const modal = document.getElementById("myModal");
const modalTtile = document.getElementById("modal-title");
const modalDesc=document.getElementById("modal-desc");
const modalImages=document.getElementById("pop-up-images");

map.on('singleclick', function (evt) {
  const coordinate = evt.coordinate;
  var feature = map.forEachFeatureAtPixel(evt.pixel,
    function (feature) {
      return feature;
    });

  if (feature) {
    if (feature.get('name') !== undefined) {
      modalImages.innerHTML="";
          modal.style.display = "block";
          modalTtile.innerHTML = feature.get('name');
          //var image="/uploads/images/"+feature.get('number')+".jpg";*/
          if (feature.get('desc') !== null && feature.get('desc') !== "-" && feature.get('desc') !== undefined )
          { 
            modalDesc.innerHTML = '<div>' + feature.get('desc') + '</div>';
            //modalImages.innerHTML ='<img src="'+image+'" >';
          }
          else
          modalDesc.innerHTML = "";
      }
      if (feature.get("type") == "Index") {
        const current = new Polygon(JSON.parse('[[' + feature.get("agurbCord").polygonCord + ']]'));
        const polygon = current;
        view.fit(polygon, { duration: 1000, padding: [170, 50, 30, 150], minResolution: 0.1 });
    }
  }
});
map.on('pointermove', (e) => {
  const pixel = map.getEventPixel(e.originalEvent);
  const hit = map.hasFeatureAtPixel(pixel);
  document.getElementById('map').style.cursor = hit ? 'pointer' : '';
});






function escapeHtml(str) {
  var map =
  {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };
  return str.replace(/[&<>"']/g, function (m) { return map[m]; });
}

$('.zoomPlace').click(function () {
  var index = $(this).index('.zoomPlace');
  var place = $('.zoomPlace').eq(index).attr("data-place-id");

  map.getLayers().forEach(layer => {
    if (layer.get('name') && layer.get('name') == 'mylayer'){
        map.removeLayer(layer)
    }
  });
  var placeId = parseInt(place);
  for (let i = 0; i < dataPlaces.length; i++) {
    if (dataPlaces[i].agurbCoords !== null) {
      if (dataPlaces[i].id === placeId) {
        if (dataPlaces[i].agurbCoords["type"] === "polygon") {
          const current = new Polygon(JSON.parse('[[' + dataPlaces[i].agurbCoords["polygonCoord"] + ']]'));
          const polygon = new Feature(current);
        
          polygon.set('style',new Style({
            stroke: new Stroke({
              color: 'transparent',
              width: 0,
            }),
            fill: new Fill({
              color: 'rgba(0, 255,0 , 0.3)',
            }),
            cursor: 'pointer'
        
          }))
          
          polygon.set('name',dataPlaces[i].name);
          
          if (dataPlaces[i].description!==null && dataPlaces[i].description!=="" && dataPlaces[i].description!==undefined)  {
            polygon.set('desc',dataPlaces[i].description);
          }
          const vectorLayer = new VectorLayer({
            style: function (feature) {
              return feature.get('style');
            },
            source: new VectorSource({ features: [polygon] }),
          });
          vectorLayer.set('name', 'mylayer');
         
         
          
          map.addLayer(vectorLayer);
        
 
          view.fit(current, { duration: 1000, padding: [170, 50, 30, 150], minResolution: 0.1 });
        }
        if (dataPlaces[i].agurbCoords["type"] === "circle") {

          
          var circleX = parseFloat(dataPlaces[i].agurbCoords["coordX"]);
          var circleY = parseFloat(dataPlaces[i].agurbCoords["coordY"]);
          var circleRadius = parseFloat(dataPlaces[i].agurbCoords["radius"])-0.791828941161384;
          var centerCircle = [circleX, circleY];
          const circle = new Circle(centerCircle, circleRadius);
          const featureCircle=new Feature(circle);
          featureCircle.set('style',new Style({
            stroke: new Stroke({
              color: 'transparent',
              width: 0,
            }),
            fill: new Fill({
              color: 'rgba(0, 255, 0, 0.3)',
            }),
            cursor: 'pointer'
        
          }));
          featureCircle.set('name',dataPlaces[i].name);
          
          if (dataPlaces[i].description!==null && dataPlaces[i].description!=="" && dataPlaces[i].description!==undefined)  {
            featureCircle.set('desc',dataPlaces[i].description);
          }
          const vectorLayer = new VectorLayer({
            style: function (feature) {
              return feature.get('style');
            },
            source: new VectorSource({ features: [featureCircle] }),
          });
          vectorLayer.set('name', 'mylayer');
         
         
          
          map.addLayer(vectorLayer);
          view.fit(circle, { duration: 1000, padding: [170, 50, 30, 150], minResolution: 0.1 });

        }
      }

    }


  }
});


$('#search1').autocomplete({
lookup: names,
onSelect: function (suggestion) {
  var searchInput = $("#search").val();
  map.getLayers().forEach(layer => {
    if (layer.get('name') && layer.get('name') == 'mylayer'){
        map.removeLayer(layer)
    }
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: ajaxUrl,
    data: { "search": searchInput },

    success: function (data, textStatus, request) {
      
      if (data[0].agurbCoords !== null) {
       
          if (data[0].agurbCoords["type"] === "polygon") {
            const current = new Polygon(JSON.parse('[[' + data[0].agurbCoords["polygonCoord"] + ']]'));
            const polygon = new Feature(current);
            polygon.set('style',new Style({
              stroke: new Stroke({
                color: 'transparent',
                width: 0,
              }),
              fill: new Fill({
                color: 'rgba(0, 255,0 , 0.3)',
              }),
              cursor: 'pointer'
          
            }))
            
            polygon.set('name',data[0].name);
          
            if (data[0].description!==null && data[0].description!=="" && data[0].description!==undefined)  {
              polygon.set('desc',data[0].description);
            }
            const vectorLayer = new VectorLayer({
              style: function (feature) {
                return feature.get('style');
              },
              source: new VectorSource({ features: [polygon] }),
            });
            vectorLayer.set('name', 'mylayer');  
            map.addLayer(vectorLayer);

            view.fit(current, { duration: 1000, padding: [170, 50, 30, 150], minResolution: 0.1 });
          }
          if (data[0].agurbCoords["type"] === "circle") {
            var circleX = parseFloat(data[0].agurbCoords["coordX"]);
            var circleY = parseFloat(data[0].agurbCoords["coordY"]);
            var circleRadius = parseFloat(data[0].agurbCoords["radius"])-0.791828941161384;;
            var centerCircle = [circleX, circleY];
            const circle = new Circle(centerCircle, circleRadius);
            const featureCircle = new Feature(circle);
            featureCircle.set('style',new Style({
              stroke: new Stroke({
                color: 'transparent',
                width: 0,
              }),
              fill: new Fill({
                color: 'rgba(0, 255, 0, 0.3)',
              }),
              cursor: 'pointer'
          
            }));
            featureCircle.set('name',data[0].name);
            
            if (data[0].description!==null && data[0].description!=="" && data[0].description!==undefined)  {
              featureCircle.set('desc',data[0].description);
            }
            const vectorLayer = new VectorLayer({
              style: function (feature) {
                return feature.get('style');
              },
              source: new VectorSource({ features: [featureCircle] }),
            });
            vectorLayer.set('name', 'mylayer');
            map.addLayer(vectorLayer);

            view.fit(circle, { duration: 1000, padding: [170, 50, 30, 150], minResolution: 0.1 });         
        }  
      }  
    }
  });
}
});








